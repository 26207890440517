/*Classes for the displayed toast*/
.Toastify__toast_container {
	word-break: break-word;
}

.Toastify__toast-container--bottom-right {
	padding: 10px;
}

.Toastify__toast {
	border-radius: 5px;
	border-width: 1px 1px 1px 16px;
	border-style: solid;
	border-color: #e5e5e5;
	text-align: center;
	text-align: left;
}

.Toastify__toast--success {
  	border-left: 16px solid #3cbe8d;
}
.Toastify__toast--success .Toastify__toast-icon svg{
 	fill:  #3cbe8d;
}

.Toastify__toast--error {
  	border-left: 16px solid #f264a6;
}
.Toastify__toast--error .Toastify__toast-icon svg{
  	fill:  #f264a6;
}